<template>
    <div class="main-content position-relative max-height-vh-100 h-100">
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">Lista de Ordens de Serviço</p>
                                <router-link to="/AbrirOS" class="btn btn-primary btn-sm ms-auto">
                                    <i class="fa fa-plus"></i> Adicionar Ordem de Serviço
                                </router-link>
                            </div>
                        </div>
                        <div style="min-height:60vh" class="card-body">
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <div v-if="loading" class="text-center">
                                        <i class="fa fa-spinner fa-spin fa-3x"></i>
                                        <p>Carregando...</p>
                                    </div>
                                    <table v-else id="ordensServicoTable" class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Responsável</th>
                                                <th scope="col">Solicitação</th>
                                                <th scope="col">Valor Final</th>
                                                <th scope="col">Telefone</th>
                                                <th scope="col">Data de Cadastro</th>
                                            </tr>
                                        </thead>
                                        <tbody style="cursor:pointer">
                                            <tr v-for="(ordem, index) in ordensServico" :key="index"
                                                @click="goToOrdem(ordem.id)">
                                                <th scope="row">{{ ordem.id }}</th>
                                                <td>{{ ordem.contato }}</td>
                                                <td>{{ ordem.responsavel }}</td>
                                                <td>{{ ordem.solicitacao }}</td>
                                                <td>{{ formatCurrency(ordem.valor_total) }}</td>
                                                <td>{{ formatTelefone(ordem.telefone) }}</td>
                                                <td>{{ formatDate(ordem.data_cadastro) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import $ from 'jquery';
import 'datatables.net';
import { format } from 'date-fns';

export default {
    data() {
        return {
            ordensServico: [],
            loading: true
        };
    },
    async mounted() {
        await this.fetchOrdensServico();
        this.$nextTick(() => {
            $('#ordensServicoTable').DataTable({
                order: [[0, 'desc']],
                language: {
                    search: "Pesquisar:",
                    lengthMenu: "Mostrar _MENU_ Ordens de Serviço",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ ordens de serviço",
                    infoEmpty: "Mostrando 0 a 0 de 0 entradas",
                    infoFiltered: "(filtrado de _MAX_ entradas totais)",
                    loadingRecords: "Carregando...",
                    zeroRecords: "Nenhum registro encontrado",
                    emptyTable: "Nenhum dado disponível na tabela",
                    paginate: {
                        first: "Primeiro",
                        previous: "Anterior",
                        next: "Próximo",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": ativar para ordenar a coluna em ordem crescente",
                        sortDescending: ": ativar para ordenar a coluna em ordem decrescente"
                    }
                }
            });
        });
    },
    methods: {
        async fetchOrdensServico() {
            try {
                this.loading = true;
                const response = await axios.get('/ordemservico');
                console.log(response.data);
                this.ordensServico = response.data.map(ordem => ({
                    ...ordem,
                    cliente: ordem.cliente_id,
                    telefone: ordem.telefone || 'Não disponível',
                    produtos: ordem.produtos || []
                }));
            } catch (error) {
                console.error('Erro ao buscar ordens de serviço:', error);
            } finally {
                this.loading = false;
            }
        },
        goToOrdem(ordemId) {
            this.$router.push(`/EditarOS/${ordemId}`);
        },
        formatDate(dateString) {
            if (!dateString) return 'Não disponível';
            return format(new Date(dateString), 'dd/MM/yyyy');
        },
        formatCurrency(value) {
            if (value == null) return 'R$ 0,00';
            const number = parseFloat(value);
            if (isNaN(number)) return 'R$ 0,00';
            return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        formatTelefone(telefone) {
            return telefone ? telefone : 'Não disponível';
        }
    }
};
</script>

<style scoped>

</style>
