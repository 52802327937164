<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Cadastro de Cliente</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="handleSubmit">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="razao_social" class="form-label">Razão Social*</label>
                                    <input id="razao_social" v-model="formData.razao_social" class="form-control"
                                        placeholder="Razão Social" required />
                                </div>

                                <div class="col-md-6">
                                    <label for="nome_fantasia" class="form-label">Nome Fantasia*</label>
                                    <input id="nome_fantasia" v-model="formData.nome_fantasia" class="form-control"
                                        placeholder="Nome Fantasia" required />
                                </div>

                                <div class="col-md-6">
                                    <label for="cnpj" class="form-label">CNPJ*</label>
                                    <input id="cnpj" v-model="formData.cnpj" class="form-control" placeholder="CNPJ"
                                        required />
                                </div>

                                <div class="col-md-6">
                                    <label for="endereco" class="form-label">Endereço*</label>
                                    <input id="endereco" v-model="formData.endereco" class="form-control"
                                        placeholder="Endereço" required />
                                </div>

                                <div class="col-md-12">
                                            <label for="obs" class="form-label">Descrição*</label>
                                            <textarea id="obs" v-model="formData.obs" class="form-control"
                                            placeholder="Descrição" required></textarea>
                                        </div>

                                <div class="col-md-6">
                                    <label for="email" class="form-label">Email*</label>
                                    <input id="email" v-model="formData.email" class="form-control" placeholder="Email"
                                        required />
                                </div>

                                <div class="col-md-6">
                                    <label for="telefone" class="form-label">Telefone*</label>
                                    <input id="telefone" v-model="formData.telefone" class="form-control"
                                        placeholder="Telefone" required />
                                </div>
                                <div class="col-md-6">
                                    <label for="ie" class="form-label">IE*</label>
                                    <input id="ie" v-model="formData.ie" class="form-control"
                                        placeholder="ie"/>
                                </div>
                                <div class="col-md-6">
                                    <label for="im" class="form-label">IM*</label>
                                    <input id="im" v-model="formData.ie" class="form-control"
                                        placeholder="im"/>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <hr />
                                    <button type="submit" class="btn btn-primary float-end btn-lg">
                                        <i class="fa fa-check"></i> Enviar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            formData: {
                razao_social: '',
                nome_fantasia: '',
                cnpj: '',
                endereco: '',
                obs: '',
                email: '',
                telefone: '',
                ie: '',
                im: ''
            }
        };
    },
    methods: {
        async handleSubmit() {
            Swal.fire({
                title: 'Salvando...',
                text: 'Aguarde enquanto o cliente está sendo cadastrado.',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            try {
                await axios.post('/clientes', this.formData);
                Swal.close();
                Swal.fire('Sucesso', 'Cliente cadastrado com sucesso!', 'success');

                this.formData = {
                    razao_social: '',
                    nome_fantasia: '',
                    cnpj: '',
                    endereco: '',
                    obs: '',
                    email: '',
                    telefone: '',
                    ie: '',
                    im: ''
                };
            } catch (error) {
                Swal.close();

                Swal.fire('Erro', 'Erro ao cadastrar cliente.', 'error');
            }
        }
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>